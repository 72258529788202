import React, { useEffect } from 'react';
import HtmlHead from '../components/html-head';
import HeroLandingComponent from '../components/landing-components/hero-landing';
import LandingNav from '../components/landing-components/nav.tsx';
import AuthorSection from '../components/landing-components/author-section';
import heroimg from '../assets/landing-page/salesforce hero img.png';
import chris from '../assets/landing-page/Chris.png';
import adam from '../assets/landing-page/Adam.png';
import hexagon from '../assets/landing-page/hexagon.png';
import cority from '../assets/landing-page/cority.png';
import LandingFooter from '../components/landing-components/landing-footer';
import LandingCtaBlock from '../components/landing-components/landing-cta';
import { graphql } from 'gatsby';
import { PageData } from '../types';

const SalesForce: React.FC<PageData> = (resp) => {
    const seoData = resp?.data?.allContentfulPageSeoData?.edges[0]?.node;

    const hero = {
        formName: 'ai-for-salesforce',
        title: 'Love <span>Salesforce</span> More',
        image: heroimg,
        formId: '4b419448-13c9-402e-8eb7-e251979a6585'
    };

    const author = [
        {
            name: '<span>Chris</span> Slaugh',
            title: 'VP Global Customer Support',
            quote: "Now our new analysts come on board and they hit that <span class='quote-imp'>Time-to-Productivity</span> number within 2 months.",
            photo: chris,
            company: cority
        },
        {
            name: '<span>Adam</span> Savage',
            title: 'Chief Operating Officer',
            quote: "Our <span class='quote-imp'>Agent Efficiency</span> is up by <span class='quote-num'>50% </span> with AptEdge, reducing headcount and onboarding costs",
            photo: adam,
            company: hexagon
        },
    ]

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <LandingNav/>
            <HeroLandingComponent hero={hero} />
            <AuthorSection author={author}/>
            <LandingCtaBlock/>
            <LandingFooter/>
            {/* @ts-ignore */}
            <style jsx>
                {
                `.intercom-lightweight-app {
                    display: none !important;
                }`
                }
            </style>
        </>
    );
}

export default SalesForce;

export const query = graphql`
    query SalesForceQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/ai-for-salesforce" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;